.info-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: slideInFade 0.5s ease-in-out forwards;
  animation-delay: 0.3s;
}
.collapse-button {
    background: transparent;
    border: none;
    cursor: pointer;
    width: 34px;
    height: 34px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #475569;
    transition: all 0.2s ease;
}

.collapse-button:hover {
    opacity: 0.7;
}

.collapse-button.collapsed {
    transform: rotate(-90deg);
}

.collapse-button.collapsed:hover {
    transform: rotate(-90deg) translateX(-1px);
}
.thesis-tabs {
  display: flex;
  gap: 10px;
  margin: 15px 0;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
}

.tab {
  padding: 8px 16px;
  border: none;
  background: none;
  color: #64748b;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.tab:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.tab.active {
  color: var(--primary-color);
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
}

.thesis-content {
  line-height: 1.6;
}

.info-text {
  line-height: 1.6;
  color: #334155;
  margin-top: 20px;
}

.section-heading {
  color: #1e293b;
  font-size: 1.1rem;
  margin: 2rem 0 1rem;
}

.section-paragraph {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #334155;
}

.section-list {
  padding-left: 2rem;
  margin: 1rem 0;
}

.section-item {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #334155;
}

.section-strong {
  color: #1e293b;
  font-weight: 600;
}

.thesis-error {
  color: var(--error-color);
  padding: 20px;
  text-align: center;
}

@keyframes slideInFade {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.regenerate-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
} 