/* src/TickerSearch.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --font-family: 'Inter', sans-serif;
    --font-size-small: 0.9em;
    --font-size-medium: 1em;
    --font-size-large: 1.2em;
    --font-size-xlarge: 1.5em;
    --button-bg-gradient: linear-gradient(145deg, #e0e0e0, #ffffff); 
    --button-shadow-light: rgba(255, 255, 255, 0.7);
    --button-shadow-dark: rgba(0, 0, 0, 0.15);
    --button-text-color: #333333; 
    --glass-bg: rgba(255, 255, 255, 0.2); 
    --glass-border: rgba(255, 255, 255, 0.3);

    --sidebar-width: 17%;
    --main-content-width: 60%;
    --news-width: 23%;
}

/* Base Styles */
body {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Layout */
.app-container {
    display: block;
}

.main-content {
    background: linear-gradient(to right, #f8fafc, #ffffff);
    margin-left: var(--sidebar-width);
    width: var(--main-content-width);
    padding: 2%;
    min-height: 100vh;
    padding-bottom: 80px;
}

.homepage-heading {
    font-size: var(--font-size-xlarge);
    margin-bottom: 20px;
}

/* Button */
.button {
    padding: 12px;
    font-size: var(--font-size-medium);
    font-weight: bold;
    color: var(--button-text-color);
    background: var(--button-bg-gradient);
    border: none;
    border-radius: 4px;
    box-shadow: 2px 2px 6px var(--button-shadow-dark), -2px -2px 6px var(--button-shadow-light);
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
    transform: scale(1.05);
    /* Slightly enlarge on hover */
}

.button:active {
    background-color: #0070f3;
    transform: scale(1);
}

.button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 112, 243, 0.2);
}

.error-message {
    color: red;
    margin-top: 20px;
}

.search-button:disabled,
.delete-button:disabled,
.submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.spinner-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    height: 100vh;
    /* Full height of the viewport */
}

.spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px; /* Size of the spinner */
    height: 50px; /* Size of the spinner */
    animation: spin 1s linear infinite; /* Animation */
    margin: auto; /* Center the spinner */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 1200px) {
    .main-content {
        padding-bottom: 80px;
        margin-left: 0;
        width: 70%;
        padding: 3vh;
    }
}

@media (max-width: 600px) {
    .main-content {
        margin-left: 0;
        width: 100%;
        padding: 3vh;
        padding-bottom: 80px;
    }
}