.sidebar {
    width: var(--sidebar-width);
    background-color: #1e1e1e;
    color: #fff;
    padding: 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.sidebar-title-button {
    background: none;
    border: none;
    color: #ffffff;
    /* White text color */
    font-size: 1.5em;
    font-weight: bold;
    cursor: pointer;
    text-align: left;
    padding: 10px 0;
    /* Add vertical padding for spacing */
    margin-bottom: 20px;
    /* Add margin below for spacing */
    transition: background-color 0.3s, transform 0.2s;
    border-bottom: 2px solid #444;
}

.sidebar-title-button:hover {
    color: #cccccc;
    transform: scale(1.01);
}

.sidebar-title-button:disabled {
    cursor: not-allowed;
}

.sidebar-subtitle {
    font-size: 18px;
    margin-bottom: 10px;
}

.search-list {
    list-style: none;
    padding-bottom: 20px;
}

.search-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    opacity: 0;
    transform: translateY(20px);
    animation: cascadeIn 0.5s forwards;
}
    
@keyframes cascadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.search-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
}

.search-date {
    color: #888;
    font-size: 0.8rem;
    margin-left: 8px;
}

.search-content {
    display: flex;
    align-items: center;
    flex: 1;
}

.search-button:hover {
    background-color: #444;
}

.search-item:hover .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button {
    display: none;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: transparent;
    color: #999;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    padding: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.delete-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

@media (max-width: 1200px) {
    .sidebar {
        display: none;
    }
}

@media (max-width: 600px) {
    .sidebar {
        display: none;
    }
}