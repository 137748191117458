.news-sidebar {
    width: var(--news-width);
    padding: 20px 25px;
    background-color: #f8f9fa;
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border-left: 1px solid #e5e7eb;
    animation: fadeInSection 0.5s ease-in-out forwards;
}

.news-container {
    height: 100%;
    padding-top: 5%;
    overflow-y: auto;
    opacity: 0;
    animation: slideInFade 0.5s ease-in-out forwards;
    animation-delay: 0.6s;
}

@keyframes slideInFade {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.news-container h3 {
    /* margin: 0 0 1.5rem 0; */
    font-size: 1.2rem;
    color: #1a1a1a;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #e5e7eb;
}

.news-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.news-item {
    padding: 1rem;
    margin-bottom: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease;
    opacity: 0;
    animation: slideInFade 0.5s ease-in-out forwards;
    animation-delay: var(--delay);
}

.news-item:hover {
    transform: translateY(-2px);
}

.news-item a {
    text-decoration: none;
    color: inherit;
    display: block;
}

.news-item h4 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    line-height: 1.4;
    color: #1a1a1a;
}

.news-item p {
    margin: 0.25rem 0;
    color: #666;
    font-size: 0.85rem;
}

.news-container h3 {
    margin: 0 0 2rem 0;
    /* Increased bottom margin */
    font-size: 1.3rem;
    /* Slightly larger font */
    color: #1a1a1a;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #e5e7eb;
    font-weight: 600;
}

.news-item {
    /* padding: 1.25rem; */
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    border: 1px solid #f0f0f0;
}

.news-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.news-item a {
    text-decoration: none;
    color: inherit;
    display: block;
}

.news-item h4 {
    font-size: 1.05rem;
    line-height: 1.5;
    color: #1a1a1a;
    font-weight: 600;
}

.news-author {
    margin: 0.5rem 0;
    color: #555;
    font-size: 0.9rem;
}

.news-date {
    color: #888;
    font-size: 0.85rem;
    padding-top: 0.75rem;
    border-top: 1px solid #f0f0f0;
}

.news-loading {
    text-align: center;
    padding: 2rem;
    color: #666;
    font-size: 0.95rem;
}

.news-empty {
    text-align: center;
    padding: 2rem;
    color: #666;
    font-size: 0.95rem;
    background: white;
    border-radius: 10px;
    border: 1px dashed #e0e0e0;
}

/* Add animation delay for each news item */
.news-item:nth-child(1) { animation-delay: 0.7s; }
.news-item:nth-child(2) { animation-delay: 0.8s; }
.news-item:nth-child(3) { animation-delay: 0.9s; }
.news-item:nth-child(4) { animation-delay: 1.0s; }
.news-item:nth-child(5) { animation-delay: 1.1s; }

@media (max-width: 1200px) {
    .news-sidebar {
        width: 30%;
    }
}

@media (max-width: 600px) {
    .news-sidebar {
        display: none;
    }
}