.info-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: slideInFade 0.5s ease-in-out forwards;
  animation-delay: 0.3s;
}

.info-text {
  line-height: 1.6;
  color: #334155;
  margin-top: 20px;
}

.section-heading {
  color: #1e293b;
  font-size: 1.1rem;
  margin: 2rem 0 1rem;
}

.section-paragraph {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #334155;
}

.section-list {
  padding-left: 2rem;
  margin: 1rem 0;
}

.section-item {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #334155;
}

.section-strong {
  color: #1e293b;
  font-weight: 600;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid #e5e7eb;
}

.analysis-heading {
  font-size: var(--font-size-large);
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

@keyframes slideInFade {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.collapse-button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #475569;
  transition: transform 0.2s ease;
  padding: 0;
}

.collapse-button:hover {
  opacity: 0.7;
}

.collapse-button.collapsed {
  transform: rotate(-90deg);
}

.collapsible-content {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  /* max-height: 2000px; */
  opacity: 1;
  overflow: hidden;
  /* padding-bottom: 10px; */
}

.collapsible-content.collapsed {
  max-height: 0;
  opacity: 0;
}

.regenerate-button {
  margin: 10px;
}