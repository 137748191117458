.auth-page {
    background: linear-gradient(to right, #f8fafc, #e2e8f0);
    padding: 2rem;
    min-height: 100vh;
}

.auth-page-content {
    margin: 0 auto;
    display: flex;
}

.auth-page-left {
    padding-left: 2rem;
    padding-right: 2rem;
    flex: 1;
}

.auth-page-right {
    width: 30%;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.auth-page-title {
    font-size: 3.5rem;
    font-weight: 800;
    color: #1a1a1a;
    animation: fadeIn 0.5s ease-in-out;
}

.auth-page-subtitle {
    font-size: 2rem;
    color: #4a5568;
    animation: fadeIn 0.5s ease-in-out;
    animation-delay: 0.1s;
    opacity: 0;
    animation-fill-mode: forwards;
    margin-bottom: 2.5rem;
}

.auth-container {
    position: sticky;
    top: 2rem;
    background: white;
    padding: 3rem 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.5s ease-in-out;
    animation-delay: 0.2s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.auth-container h2 {
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 2rem;
    font-size: 1.8rem;
}

.auth-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.auth-container input {
    padding: 12px;
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.auth-container input:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.auth-container button[type="submit"] {
    padding: 14px;
    background: #3182ce;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
}

.auth-container button[type="submit"]:hover {
    background: #2c5282;
    transform: translateY(-2px);
}

.auth-container .error {
    color: #e53e3e;
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: bold;
}

.toggle-auth {
    background: none;
    border: none;
    color: #3182ce;
    cursor: pointer;
    margin-top: 1rem;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    text-decoration: underline;
}

.toggle-auth:hover {
    text-decoration: underline;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Add loading state styles */
.auth-container button[type="submit"]:disabled {
    background: #cbd5e0;
    cursor: not-allowed;
}

/* Modify MarketIndices styles for auth page */
.auth-page .market-indices-container {
    animation: fadeIn 0.5s ease-in-out;
    animation-delay: 0.3s;
    opacity: 0;
    animation-fill-mode: forwards;
    margin-bottom: 2rem;
}

.auth-page .most-container {
    animation: fadeIn 0.5s ease-in-out;
    animation-delay: 0.4s;
    opacity: 0;
    animation-fill-mode: forwards;
}

.auth-footer {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
    text-align: center;
}

.auth-disclaimer {
    font-size: 0.75rem;
    color: #6b7280;
    text-align: center;
    margin-bottom: 1rem;
    line-height: 1.4;
}

.auth-footer-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.auth-footer-icons .footer-icon {
    color: #2d3748;
    font-size: 1.5rem;
    transition: color 0.2s ease, transform 0.2s ease;
}

.auth-footer-icons .footer-icon:hover {
    color: #3182ce;
    transform: translateY(-2px);
}

@media (max-width: 1200px) {
    .auth-page-content {
        flex-direction: column;
        gap: 2rem;
    }

    .auth-page-right {
        width: 100%;
        margin: 0 auto;
    }
    .auth-container {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
    }
}

@media (max-width: 600px) {
    .auth-page {
        padding: 0;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .auth-page-title {
        font-size: 3rem;
    }
    .auth-page-subtitle {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }
}