.most-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.most-container h2 {
  color: #333;
}

.most-header {
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 2px solid #e5e7eb;
}

.most-heading {
    font-size: var(--font-size-large);
    font-weight: 600;
    color: var(--text-color);
}

.last-updated {
    font-size: 0.5rem;
    color: #6b7280;
}

.movers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.mover-card {
  background: #f8f9fa;
  padding: 8px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  transition: background-color 0.2s ease;
}

.mover-card:hover {
  background-color: #e9ecef;
}

.mover-info {
  display: flex;
  flex-direction: column;
}

.mover-ticker {
  font-weight: bold;
  font-size: 1em;
  color: #2c3e50;
  margin-bottom: 2px;
}

.mover-price {
  font-size: 0.9em;
  color: #34495e;
  margin: 0;
}

.mover-change {
  font-weight: bold;
  font-size: 0.9em;
}

.mover-change.positive {
  color: #2ecc71;
}

.mover-change.negative {
  color: #e74c3c;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 

@media (max-width: 600px) {
    .movers-grid {
        max-height: 200px;
        overflow-y: auto;
    }
}