/* Define a global font size variable */
:root {
    --font-size-small: 0.85em; /* Define a variable for small font size */
}

.app-footer {
    text-align: center;
    padding: 12px 20px;
    background-color: #f8f9fa;
    color: #4a5568;
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--sidebar-width);
    bottom: 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
    font-size: var(--font-size-small);
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 20px;
    z-index: 1000;
    position: fixed;
}

.app-footer p {
    margin: 0;
    max-width: 800px;
    line-height: 1.4;
    justify-self: center;
    grid-column: 1;
}

.footer-icons {
    display: flex;
    gap: 20px;
    align-items: center;
    padding-right: 20px;
    grid-column: 2;
}

.footer-icon {
    color: #2d3748;
    font-size: 20px;
    transition: all 0.2s ease;
}

.footer-icon:hover {
    color: #3182ce;
    transform: translateY(-2px);
}

.footer-logout-button {
    background-color: #e53e3e;
    font-size: var(--font-size-small);
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: auto;
}

.footer-logout-button:hover {
    background-color: #c53030;
}

.footer-login-button {
    background-color: #3182ce;
}

.footer-login-button:hover {
    background-color: #2c52b2;
}

.footer-icons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 1200px) {
    .app-footer {
        margin-left: 0;
        width: 100%;
    }
}

@media (max-width: 600px) {
    .app-footer {
        font-size: 0.75rem;
        margin-left: 0;
        width: 100%;
    }
}