.form {
    width: 100%;
}

.form-field-container {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    margin-bottom: 20px;
}

.form-field {
    flex: 1;
    position: relative;
}

.input {
    width: 100%;
    padding: 12px;
    font-size: var(--font-size-medium);
    border: 2px solid #e2e8f0;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.input:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(49, 130, 206, 0.1);
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 1000;
    margin-top: 4px;
}

.dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-item:hover {
    background-color: #f7fafc;
}

.dropdown-item.highlighted {
    background-color: #e0e0e0;
}

.submit-button {
    padding: 12px 24px;
    white-space: nowrap;
} 