.fundamentals-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: slideInFade 0.5s ease-in-out forwards;
  animation-delay: 0.3s;
}

.fundamentals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.fundamentals-section {
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
  opacity: 0;
  animation: fadeInSection 0.5s ease-in-out forwards;
}

.fundamentals-heading {
    font-size: var(--font-size-large);
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 2px solid #e5e7eb;
}

/* Animation delays for sections */
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(1) { animation-delay: 0s; }
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(2) { animation-delay: 0.2s; }
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(3) { animation-delay: 0.4s; }
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(4) { animation-delay: 0.6s; }
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(5) { animation-delay: 0.8s; }
.collapsible-content:not(.collapsed) .fundamentals-section:nth-child(6) { animation-delay: 1s; }

@keyframes fadeInSection {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fundamentals-section h3 {
  color: #2c3e50;
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.fundamentals-section p {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #4a5568;
}

.website-link {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}

.fundamentals-section strong {
  color: #2d3748;
}

.fundamentals-section a {
  color: #3182ce;
  text-decoration: none;
  display: inline-block;
  max-width: 100%;
}

.fundamentals-section a:hover {
  text-decoration: underline;
}

.price-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
}

.current-price {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2d3748;
}

.price-change {
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-block;
}

.price-change.positive {
  color: #059669;
  background: #d1fae5;
}

.price-change.negative {
  color: #dc2626;
  background: #fee2e2;
}

.price-timestamp {
  font-size: 0.85rem;
  color: #6b7280;
  margin-top: 8px;
  text-align: center;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid #e5e7eb;
}

.fundamentals-heading {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.collapse-button {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #475569;
  transition: all 0.2s ease;
}

.collapse-button:hover {
  opacity: 0.7;
}

.collapse-button.collapsed {
  transform: rotate(-90deg);
}

.collapse-button.collapsed:hover {
  transform: rotate(-90deg) translateX(-1px);
}

.collapsible-content {
  /* transition: max-height 0.3s ease-out, opacity 0.3s ease-out; */
  /* max-height: 2000px; */
  opacity: 1;
  overflow: hidden;
}

.collapsible-content.collapsed {
  max-height: 0;
  opacity: 0;
} 
