.market-indices-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.market-indices-heading {
    font-size: var(--font-size-large);
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 24px;
    padding-bottom: 16px;
    border-bottom: 2px solid #e5e7eb;
}

.indices-grid {
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
}

.index-card h3 {
    color: #2c3e50;
    margin-bottom: 15px;
    font-size: 1.1rem;
}

.market-indices-section {
    padding: 15px;
    background: #f8f9fa;
    border-radius: 6px;
    opacity: 0;
    animation: fadeInSection 0.5s ease-in-out forwards;
    animation-delay: var(--delay);
}

.price-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 15px 0;
}

.current-price-index {
    font-size: var(--font-size-xlarge);
    font-weight: bold;
    color: #2d3748;
}

.price-change-index {
    font-size: var(--font-size-small);
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    display: inline-block;
}

.price-change-index.positive {
    color: #059669;
    background: #d1fae5;
}

.price-change-index.negative {
    color: #dc2626;
    background: #fee2e2;
}

.price-timestamp-index {
    font-size: 0.5rem;
    color: #6b7280;
    margin-top: 8px;
    text-align: center;
}

@keyframes fadeInSection {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
} 

@media (max-width: 600px) {
    .indices-grid {
        max-height: 400px;
        overflow-y: auto;
    }
}