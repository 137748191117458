.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    margin-top: 24px;
    margin-bottom: 24px;
    /* margin-right: 40%; */
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.loading-text {
    font-size: var(--font-size-large);
    color: var(--text-color, #334155);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}

.loading-text span:not(.loading-dots):not(.dot) {
    color: var(--primary-color, #2563eb);
    font-weight: 600;
    margin: 0 4px;
}

.loading-dots {
  display: inline-flex;
}

.dot {
  animation: dotFade 1.4s infinite;
  opacity: 0;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotFade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
} 