.admin-layout {
  display: flex;
  min-height: 100vh;
  background: #f8fafc;
}

.admin-sidebar {
  width: 300px;
  background: #1a1a1a;
  color: white;
  padding: 2rem;
  height: 100vh;
  position: fixed;
  left: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.admin-main {
  flex: 1;
  margin-left: 300px;
  padding: 3rem;
  max-width: 1000px;
  margin: 2rem auto 2rem 300px;
}

.admin-sidebar h2 {
  color: white;
  margin-bottom: 2rem;
  font-size: 1.75rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #333;
  font-weight: 600;
}

.users-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.user-item {
  background: #2d2d2d;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  transition: all 0.2s ease;
  border: 1px solid #333;
  width: 100%;
  animation: cascadeIn 0.5s ease forwards;
  opacity: 0;
}

.user-item:hover {
  background: #363636;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.user-info {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.user-name {
  font-weight: 600;
  font-size: 0.95rem;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-id {
  font-size: 0.85rem;
  color: #a0aec0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-count {
  font-size: 0.85rem;
  color: #90cdf4;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
}

.admin-error {
  color: #fc8181;
  background: rgba(229, 62, 62, 0.1);
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(229, 62, 62, 0.2);
}

/* Main content styles */
.admin-main h1 {
  color: #1a202c;
  margin-bottom: 2.5rem;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 600;
}

.admin-controls {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.admin-button {
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.admin-button.danger {
  background: #e53e3e;
  color: white;
  box-shadow: 0 4px 6px rgba(229, 62, 62, 0.2);
}

.admin-button.danger:hover {
  background: #c53030;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(229, 62, 62, 0.3);
}

.admin-button.danger:active {
  transform: translateY(0);
}

.admin-logout-button {
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background: #4a5568;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  font-size: 0.95rem;
  width: fit-content;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-logout-button:hover {
  background: #2d3748;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.admin-logout-button:active {
  transform: translateY(0);
}

/* Add some hover effects for interactivity */
.user-item {
  cursor: pointer;
}

@keyframes cascadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}